import React, { Component } from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import "../css/promotions.css";
import uploadsApi from "../api/uploads.api";
import { toast } from "react-toastify";

export default class BulkPromotions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promotionName: "",
      promotionURL: "",
      promotionText: "",
      imageFile: null,
      imageFileErr: "",
      imageFileName: "",
      selectedImage: null,
    };
    this.onChangeimageHandler = this.onChangeimageHandler.bind(this);
  }

  handlePromotionNameChange = (event) => {
    this.setState({
      promotionName: event.target.value,
    });
  };

  handlePromotionURLChange = (event) => {
    this.setState({
      promotionURL: event.target.value,
    });
  };

  handlePromotionTextChange = (event) => {
    this.setState({
      promotionText: event.target.value,
    });
  };

  sendPromotionDetails = async () => {
    try {
      const { promotionName, promotionURL, promotionText } = this.state;

      const promotionResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/promotions/send`,
        {
          promotion_name: promotionName,
          promotion_url: promotionURL,
          promotion_text: promotionText,
          imageFileName:
            this.state.imageFileName != ""
              ? `${process.env.REACT_APP_API_BASE_URL}/uploads/images/${this.state.imageFileName}`
              : null,
        }
      );

      this.setState({
        imageFileErr: "",
        imageFileName: promotionResponse.data.file_path,
      });
      toast.success("Успешно испратено");
    } catch (err) {
      toast.error("Неуспешно испракање на прмоцијата");
      console.log(err);
    }
  };

  async onChangeimageHandler(event) {
    try {
      const file = event.target.files[0];
      const target = event.target.files[0].name.slice(
        ((event.target.files[0].name.lastIndexOf(".") - 1) >>> 0) + 2
      );
      if (
        target.toLowerCase() == "png" ||
        target.toLowerCase() == "jpg" ||
        target.toLowerCase() == "jpeg"
      ) {
        let data = new FormData();
        data.append("file", event.target.files[0]);
        var response = await uploadsApi.uploadImage(data);
        this.setState({ imageFileName: response.data.filename, fileError: "" });
      } else {
        this.setState({
          imageFileErr:
            "Неправилен формат на фајл. Форматот мора да биде .png или .jpg",
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { imageFileErr, imageFileName } = this.state;

    return (
      <div className="promotions">
        <h2 className="promoHeading">Испрати нова промоција</h2>
        <FloatingLabel
          controlId="promotionName"
          label="Име на промоција"
          className="mb-3 MyFloatingLabel"
        >
          <Form.Control
            type="text"
            placeholder=""
            onChange={this.handlePromotionNameChange}
          />
        </FloatingLabel>
        <FloatingLabel
          controlId="promotionURL"
          label="Линк на промоција"
          className="mb-3 MyFloatingLabel"
        >
          <Form.Control
            type="text"
            placeholder=""
            onChange={this.handlePromotionURLChange}
          />
        </FloatingLabel>
        <FloatingLabel
          controlId="promotionText"
          label="Техт"
          className="MyFloatingLabel"
        >
          <Form.Control
            as="textarea"
            placeholder=""
            style={{ height: "100px" }}
            onChange={this.handlePromotionTextChange}
          />
        </FloatingLabel>

        <input
          type="file"
          name="imageFileName"
          onChange={this.onChangeimageHandler}
          value={this.state.selectedImage}
        />

        <button onClick={this.sendPromotionDetails} className="btn btn-info">
          Испрати
        </button>
      </div>
    );
  }
}
