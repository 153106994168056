import axios from "axios";

class ClientsApi {
  constructor() {
    this.baseURL = process.env.REACT_APP_API_BASE_URL
  }

  async fetchClientsData(searchText, currentPage, pageSize) {
    try {
      const apiUrl = `${this.baseURL}/users/get-all?searchText=${searchText}&page=${currentPage}&totalCount=${pageSize}`;
      const response = await axios.post(apiUrl, {
        totalCount: pageSize,
        searchText,
        page: currentPage,
      });

      if (Array.isArray(response.data.users.rows)) {
        return {
          clientsData: response.data.users.rows,
          currentUsersCount: response.data.users.count,
        };
      } else {
        throw new Error("Invalid API response. Expected an array of clients.");
      }
    } catch (error) {
      throw new Error("Error fetching clients' data");
    }
  }
}

export default new ClientsApi();
