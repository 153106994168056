import React, { Component } from "react";
import { Row, Col, Table, Form, Button, FormControl } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import ClientsApi from "../api/ClientsApi";

export default class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      clientsData: [],
      currentPage: 1,
      pageSize: 10,
      filteredData: [],
      currentUsersCount: 0,
    };
    this.handleSearch = this.handleSearch.bind(this);
  }

  async componentDidMount() {
    await this.fetchClientsData();
  }

  async fetchClientsData() {
    try {
      const { searchText, currentPage, pageSize } = this.state;

      const { clientsData, currentUsersCount } =
        await ClientsApi.fetchClientsData(searchText, currentPage, pageSize);

      this.setState({
        clientsData,
        currentUsersCount,
      });
    } catch (error) {
      toast.error(error.message);
    }
  }

  handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    this.setState({ currentPage: selectedPage }, () => {
      this.fetchClientsData();
    });
  };

  handleSearch(e) {
    console.log(e.target.value);
    this.setState({ searchText: e.target.value });
    this.fetchClientsData();
  }

  render() {
    const pageCount = Math.ceil(
      this.state.currentUsersCount / this.state.pageSize
    );

    return (
      <>
        <div className="headingAndSearch">
          <h2 className="heading ml-3 myHeading">Clients</h2>
          <div className="d-flex align-items-center">
            <Form className="mr-2">
              <FormControl
                type="text"
                placeholder="Пребарај по број"
                value={this.state.searchText}
                onChange={this.handleSearch}
              />
            </Form>
            <Button
              className="searchButton"
              variant="primary"
              onClick={() => this.fetchClientsData()}
            >
              Search
            </Button>
          </div>
        </div>

        <Row className="table-section">
          <Col lg={12}>
            <section className="list-box mt-3">
              <Table responsive="xl" className="mb-5 myTable">
                <thead className="table-head">
                  <tr>
                    <th>Вибер име</th>
                    <th>Име и Презиме</th>
                    <th>Држава</th>
                    <th>Телефон</th>
                    <th>Дата на регистрација</th>
                    <th>Прифатена понуда</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.clientsData.map((client) => (
                    <tr key={client._id}>
                      <td>{client.user_profile.name}</td>
                      <td>{client.name}</td>
                      <td>{client.user_profile.country}</td>
                      <td>{client.phone}</td>
                      <td>{client.subscribed_on}</td>
                      <td>{client.promotion_accepted == true ? "Да" : "Не"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="pagination-container">
                <ReactPaginate
                  previousLabel="Previous"
                  nextLabel="Next"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </div>
            </section>
          </Col>
        </Row>
      </>
    );
  }
}
