import React from "react";
import { Button } from "react-bootstrap";
import ExcelJS from "exceljs"; // Import exceljs library
import CodeAttempts from "../api/codeAttempts";
import "../css/main.css";



class ExportCodeAttempts extends React.Component {
  constructor(props) {
    super(props);
  
    this.state = {
      codeAttempts: [],
      dateFrom: new Date(),
      dateTo: new Date(),
      searchText: "",
      searchBy: 1,
    };
  }
  

  async componentDidUpdate(prevProps) {
    if (
      prevProps.dateFrom !== this.props.dateFrom ||
      prevProps.dateTo !== this.props.dateTo ||
      prevProps.searchText !== this.props.searchText ||
      prevProps.searchBy !== this.props.searchBy
    ) {
      try {
        const dateFrom = new Date(this.props.dateFrom);
        dateFrom.setHours(0, 0, 0, 0);

        const dateTo = new Date(this.props.dateTo);
        dateTo.setHours(23, 59, 59, 59);

        const result = await CodeAttempts.getForExport(
          dateFrom,
          dateTo,
          this.props.searchText,
          this.props.searchBy
        );
        this.setState({ codeAttempts: result.codeAttempts });
      } catch (error) {
        throw error;
      }
    }
  }

  exportToExcel() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("codeAttempts");

    // Add headers
    worksheet.addRow(["Телефон", "Платформа", "Одговор", "Испратен код", "Дата"]);

    // Add data from this.state.codeAttempts
    this.state.codeAttempts.forEach((attempt) => {
      const { phone, platform, response, code, created } = attempt;
      worksheet.addRow([phone, platform, response, code, created]);
    });

    // Create a blob from the Excel workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const blobUrl = URL.createObjectURL(blob);

      // Create a download link and trigger the download
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "Испратени кодови.xlsx";
      link.click();
    });
  }

  render() {
    return (
      <div>
        <Button
          variant="btn mr-2 button-standard"
          onClick={() => this.exportToExcel()}
          className="searchButton"
        >
          Експортирај
        </Button>
      </div>
    );
  }
}

export default ExportCodeAttempts;
