import React, { Component } from "react";
import { Row, Col, Table, Form, Button, FormControl } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import WinnersApi from "../api/winners.js";
import "../css/table.css";
import "../css/main.css";

export default class Winners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      page: 1,
      winners: null,
      count: 0,
    };
  }

  async getWinnersForAdmin() {
    try {
      var attempts = await WinnersApi.getAll();
      this.setState({ winners: attempts });
    } catch (error) {
      toast.error("Грешка при преземањето на добитниците");
      throw error;
    }
  }
  async componentDidMount() {
    await this.getWinnersForAdmin();
  }

  renderPlatform(statusId) {
    switch (statusId) {
      case 1:
        return "СМС";
      case 2:
        return "ВИБЕР";
    }
  }

  renderWinner(winner_type) {
    winner_type = parseInt(winner_type);
    switch (winner_type) {
      case 1:
        return "25.000 Денари";
      case 2:
        return "Пакет производи";
      case 3:
        return "Скутер";
      case 4:
        return "Ваучер";
      case 5:
        return "Паметен часовник";
      case 6:
        return "Звучник";
      case 7:
        return "Автомобил";
    }
  }

  render() {
    const { searchText } = this.state;

    return (
      <>
        <div className="headingAndSearch">
          <h2 className="heading ml-3 myHeading">Добитници</h2>
          <div className="d-flex align-items-center">
            <Form className="mr-2">
              <FormControl
                type="text"
                placeholder="Enter search text..."
                value={searchText}
                onChange={(e) => this.setState({ searchText: e.target.value })}
              />
            </Form>
            <Button
              className="searchButton"
              variant="primary"
              onClick={() => this.fetchClientsData()}
            >
              Search
            </Button>
            {/* <Button variant="secondary" className="ml-2">
              Export Table
            </Button> */}
          </div>
        </div>
        <Row className="table-section">
          <Col lg={12}>
            {/* <figure className="icon-group">
            <h2 className="heading ml-3 myHeading">Добитници</h2>
          </figure> */}

            <section className="list-box mt-3 myTable">
              <Table responsive="xl" className="mb-5">
                <thead className="table-head">
                  <tr>
                    <th>Код</th>
                    <th>Телефон</th>
                    <th>Платформа</th>
                    <th>Испратен на</th>
                    <th>Добитник на</th>
                    <th>Извлечен на</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.winners
                    ? this.state.winners.map((i) => {
                        let date = new Date(i.created).toLocaleString("en-GB");
                        const updated = new Date(i.updated).toLocaleDateString(
                          "en-GB"
                        );
                        return (
                          <tr key={i._id}>
                            <td>{i.code}</td>
                            <td>{i.phone}</td>
                            <td>{this.renderPlatform(i.platform)}</td>
                            <td>{date}</td>
                            <td>{this.renderWinner(i.winner_type)}</td>
                            <td>{updated}</td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </Table>
            </section>
          </Col>
        </Row>
      </>
    );
  }
}
