import React, { Component } from "react";
import {
  Row,
  Col,
  Table,
  Form,
  Button,
  FormControl,
  Modal,
} from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import axios from "axios";
import "..//css/table.css";

export default class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientsData: [],
      currentPage: 1,
      totalPages: 0,
      searchText: "",
      showModal: false,
      selectedUserName: "",
      messageText: "",
      file: null,
      previewMessage: "",
      lastUpdateFromUser: "",
    };
  }

  async fetchClientsData() {
    try {
      const apiUrl =
        "https://argeta-api.virtual-service-center.com/messages/all";
      const pageSize = 10;

      const response = await axios.post(
        apiUrl,
        {
          totalCount: pageSize,
          pageNumber: this.state.currentPage,
          searchText: this.state.searchText,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InN1cGVyYWRtaW4iLCJ1c2VySWQiOiI2MzAxZTE4MjYzMmViMjI5ZDZjNDlmZGQiLCJpc1N1cGVyQWRtaW4iOnRydWUsInJvbGUiOjEsImlhdCI6MTY5MDYxMzUzN30.lKKmm4e75T9wZNFDvlbIldMawscB-CoX5GiiTttb8Pw",
          },
        }
      );

      if (
        response.data.messages &&
        Array.isArray(response.data.messages.rows)
      ) {
        this.setState({
          clientsData: response.data.messages.rows,
          totalPages: Math.ceil(response.data.messages.count / pageSize),
        });
      } else {
        toast.error("Invalid API response. Expected an array of messages.");
      }
    } catch (error) {
      toast.error("Error fetching messages");
      throw error;
    }
  }

  async componentDidMount() {
    await this.fetchClientsData();
  }

  handleViewModal = (userName, message) => {
    this.setState({
      showModal: true,
      selectedUserName: userName,
      previewMessage: message,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      selectedUserName: "",
      messageText: "",
      file: null,
      previewMessage: "",
    });
  };

  handleMessageTextChange = (e) => {
    this.setState({
      messageText: e.target.value,
    });
  };

  handleFileChange = (e) => {
    this.setState({
      file: e.target.files[0],
    });
  };

  handleSendMessage = async () => {
    const { selectedUserName, messageText } = this.state;

    try {
      const apiUrl =
        "https://argeta-api.virtual-service-center.com/messages/send-answer";

      const response = await axios.post(
        apiUrl,
        {
          // messageId: selectedUserName,
          messageId: "64c4b4bf22944f223ee8cb9a",
          answer: messageText,
          answeredBy: "Admin",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InN1cGVyYWRtaW4iLCJ1c2VySWQiOiI2MzAxZTE4MjYzMmViMjI5ZDZjNDlmZGQiLCJpc1N1cGVyQWRtaW4iOnRydWUsInJvbGUiOjEsImlhdCI6MTY5MDYxMzUzN30.lKKmm4e75T9wZNFDvlbIldMawscB-CoX5GiiTttb8Pw",
          },
        }
      );

      // Assuming the API returns a success response with a status code of 200 or 201
      if (response.status === 200 || response.status === 201) {
        toast.success("Message sent successfully!");

        // Clear the input fields and close the modal
        this.setState({
          messageText: "",
          file: null,
          showModal: false,
          selectedUserName: "",
        });
      } else {
        toast.error("Failed to send message. Please try again later.");
      }
    } catch (error) {
      toast.error("Failed to send message. Please try again later.");
      console.error("Error sending message:", error);
    }
  };

  render() {
    const {
      clientsData,
      searchText,
      showModal,
      selectedUserName,
      messageText,
      previewMessage,
    } = this.state;

    return (
      <>
        <div className="headingAndSearch">
          <h2 className="heading ml-3 myHeading">Messages</h2>
          <div className="d-flex align-items-center">
            <Form className="mr-2">
              <FormControl
                type="text"
                placeholder="Enter search text..."
                value={searchText}
                onChange={(e) => this.setState({ searchText: e.target.value })}
              />
            </Form>
            <Button
              className="searchButton"
              variant="primary"
              onClick={() => this.fetchClientsData()}
            >
              Search
            </Button>
            {/* <Button variant="secondary" className="ml-2">
              Export Table
            </Button> */}
          </div>
        </div>

        <Row className="table-section">
          <Col lg={12}>
            <section className="list-box mt-3">
              <Table responsive="xl" className="mb-5 myTable">
                <thead className="table-head">
                  <tr>
                    <th></th>
                    {/* new message */}
                    <th>Sender Name</th>
                    <th>List Comment/Questions</th>
                    <th>Received Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {clientsData.map((message) => (
                    <tr key={message._id}>
                      <td>
                        {message.lastUpdateFromUser ? (
                          <span className="new-message-indicator">!</span>
                        ) : null}
                      </td>
                      {/* new message */}
                      <td>{message.user.name}</td> {/* Sender Name */}
                      <td>{message.question_or_comment}</td>{" "}
                      {/* comment/questions */}
                      <td>{message.date}</td> {/* received date */}
                      <td>
                        <Button
                          variant="success"
                          onClick={() =>
                            this.handleViewModal(
                              message.user.name,
                              message.question_or_comment
                            )
                          }
                        >
                          View
                        </Button>{" "}
                        <Button
                          variant="danger"
                          onClick={() => {
                            // Add your logic for the "Delete" button here
                            // For example, you can prompt the user for confirmation and then delete the message
                          }}
                        >
                          Delete
                        </Button>
                      </td>
                      {/* action */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </section>
          </Col>
        </Row>

        {/* Modal */}
        <Modal show={showModal} onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Chat with {selectedUserName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label>Enter your message:</label>
              <textarea
                className="form-control"
                value={messageText}
                onChange={this.handleMessageTextChange}
                rows={4}
              />
            </div>

            <div className="mt-3">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload image</Form.Label>
                <Form.Control type="file" onChange={this.handleFileChange} />
              </Form.Group>
            </div>

            {/* Add the preview of the message from the API here */}
            <div className="mt-3">
              <strong>Preview of the message sent by the user:</strong>
              <p>{previewMessage}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleSendMessage}>
              Send
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
