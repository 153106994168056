import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormControl,
  Button,
  Table,
  Card,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CodeAttemptsAPI from "../api/codeAttempts";
import DatePicker from "react-date-picker";
import ExportCodeAttempts from "./ExportCodeAttempts";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "../css/table.css";
import "../css/main.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

export default class CodeAttempts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      page: 1,
      searchText: "",
      attempts: null,
      dateFrom: new Date(),
      dateTo: new Date(),
      searchBy: 1,
      count: 0,
      countViber: 0,
      countSms: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.onDateFromChange = this.onDateFromChange.bind(this);
    this.onDateToChange = this.onDateToChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(event) {
    this.setState({ searchBy: event.value });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  async handlePageClick(e) {
    await this.getCodeAttepmts(e.selected + 1);
  }

  onDateFromChange(e) {
    let dateFrom = new Date();
    dateFrom.setMonth(e.getUTCMonth());
    dateFrom.setFullYear(e.getUTCFullYear());
    dateFrom.setDate(e.getDate());
    dateFrom.setHours(0, 0, 0, 0);
    this.setState({ dateFrom: dateFrom });
  }

  onDateToChange(e) {
    let dateTo = new Date();
    dateTo.setMonth(e.getUTCMonth());
    dateTo.setFullYear(e.getUTCFullYear());
    dateTo.setDate(e.getDate());
    dateTo.setHours(23, 59, 59, 59);
    this.setState({ dateTo: dateTo });
  }

  async getCodeAttepmts(page = 0) {
    try {
      let dateTo = new Date();
      dateTo.setMonth(this.state.dateTo.getUTCMonth());
      dateTo.setFullYear(this.state.dateTo.getUTCFullYear());
      dateTo.setDate(this.state.dateTo.getDate());
      dateTo.setHours(23, 59, 59, 59);
      let dateFrom = new Date();
      dateFrom.setMonth(this.state.dateFrom.getUTCMonth());
      dateFrom.setFullYear(this.state.dateFrom.getUTCFullYear());
      dateFrom.setDate(this.state.dateFrom.getDate());
      dateFrom.setHours(0, 0, 0, 0);

      var attempts = await CodeAttemptsAPI.getAll(
        page === 0 ? this.state.page : page,
        10,
        this.state.searchText,
        dateFrom,
        dateTo,
        this.state.searchBy
      );
      this.setState({
        attempts: attempts,
        count: attempts.count,
        countViber: attempts.countViber,
        countSms: attempts.countSms,
      });
    } catch (error) {
      toast.error("Грешка при преземањето на внесените кодови");
      throw error;
    }
  }
  async componentDidMount() {
    await this.getCodeAttepmts();
  }

  renderStatus(statusId) {
    switch (statusId) {
      case 1:
        return "Невалиден код";
      case 2:
        return "Кодот веќе постоеше";
      case 3:
        return "Прифатен код";
    }
  }

  renderPlatform(statusId) {
    switch (statusId) {
      case 1:
        return "СМС";
      case 2:
        return "ВИБЕР";
    }
  }
  renderStatus(status) {
    switch (status) {
      case 1:
        return "Успешно";
      case 2:
        return "Невалиден код";
      case 3:
        return "Постоечки код";
    }
  }

  render() {
    const options = [
      { value: 1, label: "Сите" },
      { value: 2, label: "СМС" },
      { value: 3, label: "ВИБЕР" },
      { value: 4, label: "Успешни" },
      { value: 5, label: "Неуспешни" },
      { value: 6, label: "Дупликати" },
    ];
    const defaultOption = options[0];
    const { searchText } = this.state;

    return (
      <>
        <div className="headingAndSearch">
          <h2 className="heading ml-3 myHeading">Испратени кодови</h2>
          <div className="d-flex align-items-center">
            <Form className="mr-2">
              <FormControl
                type="text"
                name="searchText"
                placeholder="Пребарај"
                className="search-bar"
                onChange={this.handleChange}
              />
            </Form>
            <Dropdown
              options={options}
              onChange={this.onSelect}
              placeholder="Одбери"
              className="custom-dropdown"
            />
            <Button
              variant="btn mr-2 ml-2 button-standard"
              onClick={() => this.getCodeAttepmts()}
              className="searchButton"
            >
              Пребарај
            </Button>

            <ExportCodeAttempts
              className="ml-2"
              dateFrom={this.state.dateFrom}
              dateTo={this.state.dateTo}
              searchText={this.state.searchText}
              searchBy={this.state.searchBy}
            />

            <Card className="transparent">
              <Card.Body>
                <p className="para">Дата од</p>
                <DatePicker
                  className="mb-2"
                  value={this.state.dateFrom}
                  onChange={this.onDateFromChange}
                  format="dd-MM-yyyy"
                  disableClock={true}
                  timeFormat={false}
                />
                <p className="para">Дата до</p>
                <DatePicker
                  className="mr-2"
                  value={this.state.dateTo}
                  onChange={this.onDateToChange}
                  format="dd-MM-yyyy"
                  disableClock={true}
                  timeFormat={false}
                />
                {/* <DownloadApplications
                  className="ml-2"
                  dateFrom={this.state.dateFrom}
                  dateTo={this.state.dateTo}
                /> */}
              </Card.Body>
            </Card>
          </div>
        </div>
        <Row className="table-section">
          <Col lg={12}>
            <section className="list-box mt-3 myTable">
              <Table responsive="xl" className="mb-5">
                <caption className="table-caption">
                  <p> Вкупно зписи : {this.state.count} </p>{" "}
                  {this.state.countSms ? (
                    <p>Вкупно СМС : {this.state.countSms} </p>
                  ) : null}{" "}
                  {this.state.countViber ? (
                    <p>Вкупно Вибер : {this.state.countViber}</p>
                  ) : null}
                </caption>
                <thead className="table-head">
                  <tr>
                    <th>Код</th>
                    <th>Телефон</th>
                    <th>Платформа</th>
                    <th>Статус</th>
                    <th>Испратен на</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.attempts
                    ? this.state.attempts.codes.map((i) => {
                        let date = new Date(i.created).toLocaleString("en-GB");
                        return (
                          <tr key={i._id}>
                            <td>{i.code}</td>
                            <td>{i.phone}</td>
                            <td>{this.renderPlatform(i.platform)}</td>
                            <td>{this.renderStatus(i.status)}</td>
                            <td>{date}</td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </Table>
              <div className="pagination-container">
                <ReactPaginate
                  previousLabel="Previous"
                  nextLabel="Next"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={
                    this.state.attempts && this.state.attempts.count
                      ? Math.ceil(this.state.attempts.count / 10)
                      : 1
                  }
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </div>
            </section>
          </Col>
        </Row>
      </>
    );
  }
}
