import React, { useState, useEffect } from "react";
import { Table, Form, FormControl, Button, Row, Col } from "react-bootstrap";
import axios from "axios";

const PromotionsPage = () => {
  const [searchText, setSearchText] = useState("");
  const [promotionsData, setPromotionsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const fetchPromotionsData = async () => {
    try {
      const response = await axios.get(
        "https://argeta-api.virtual-service-center.com/promotions",
        {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InN1cGVyYWRtaW4iLCJ1c2VySWQiOiI2MzAxZTE4MjYzMmViMjI5ZDZjNDlmZGQiLCJpc1N1cGVyQWRtaW4iOnRydWUsInJvbGUiOjEsImlhdCI6MTY5MDYxMzUzN30.lKKmm4e75T9wZNFDvlbIldMawscB-CoX5GiiTttb8Pw",
          },
        }
      );

      // Add the included_for_listing property to each promotion
      const promotionsWithCheckbox = response.data.promotions.map(promotion => ({
        ...promotion,
        included_for_listing: false, // Initialize checkbox value
      }));

      setPromotionsData(promotionsWithCheckbox);
      setTotalPages(
        Math.ceil(promotionsWithCheckbox.length / ITEMS_PER_PAGE)
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchPromotionsData();
  }, []);

  const ITEMS_PER_PAGE = 10;
  const offset = currentPage * ITEMS_PER_PAGE;

  // const handlePageClick = ({ selected }) => {
  //   setCurrentPage(selected);
  // };

  const handleCheckboxChange = async (promotionId) => {
    try {
      const updatedPromotions = promotionsData.map(promotion => {
        if (promotion._id === promotionId) {
          const updatedPromotion = {
            ...promotion,
            included_for_listing: !promotion.included_for_listing,
          };
          return updatedPromotion;
        }
        return promotion;
      });

      setPromotionsData(updatedPromotions);

      // Call the API to update the included_for_listing value
      await axios.post(
        "https://argeta-api.virtual-service-center.com/promotions/include-to-list",
        {
          promotion_id: promotionId,
          included_for_listing: updatedPromotions.find(p => p._id === promotionId).included_for_listing,
        }
      );
    } catch (error) {
      console.error("Error updating checkbox:", error);
    }
  };

  return (
    <>
      <div className="headingAndSearch">
        <h2 className="heading ml-3 myHeading">Promotions</h2>
        <div className="d-flex align-items-center">
          <Form className="mr-2">
            <FormControl
              type="text"
              placeholder="Enter search text..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Form>
          <Button
            className="searchButton"
            variant="primary"
            onClick={fetchPromotionsData}
          >
            Search
          </Button>
        </div>
      </div>

      <Row className="table-section">
        <Col lg={12}>
          <section className="list-box mt-3">
            <Table responsive="xl" className="mb-5 myTable">
              <thead className="table-head">
                <tr>
                  <th>ID</th>
                  <th>Promotion text</th>
                  <th>Promotion name</th>
                  <th>Created at</th>
                  <th>Вметни во листа</th>
                </tr>
              </thead>
              <tbody>
                {promotionsData
                  .slice(offset, offset + ITEMS_PER_PAGE)
                  .map((promotion) => (
                    <tr key={promotion._id}>
                      <td>{promotion._id}</td>
                      <td>{promotion.promotion_text}</td>
                      <td>{promotion.promotion_name}</td>
                      <td>{promotion.created_at}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={promotion.included_for_listing}
                          onChange={() => handleCheckboxChange(promotion._id)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </section>
        </Col>
      </Row>
    </>
  );
};

export default PromotionsPage;
